<template>
  <div>
    <div class="supplier-menu">
      <div class="supplier-menu-main">
        <div class="content">
          <a
            :href="item.url"
            v-for="item in menuList"
            :key="item.id"
            :class="['item', item.id == active ? 'on' : '']"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
export default {
  props: {
    active: {
      default: 1,
      type: Number,
    },
    banner: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menuList: [
        // { id: 1, name: "在线制造平台", url: "/make" },
        // { id: '0', name: "全部", url: "/supplier/list?cmsHelpCategoryId=004" ,cindex:"004"},
        // { id: '1', name: "精选专家", url: "/zhuanjia/list?cmsHelpCategoryId=1656455518364344323001" ,cindex:"1656455518364344323001"},
        // { id: '2', name: "顶级专家", url: "/zhuanjia/list?cmsHelpCategoryId=1656455518364344323002",cindex:"1656455518364344323002" },
        { id: 1, name: "工业资源", url: "/home?cid=1" },
        { id: 10, name: "需求对接", url: "/xuqiuduijie/list?cid=10" },
        { id: 9, name: "成果转化", url: "/keji/list?cid=9" },
        { id: 8, name: "专家库", url: "/zhuanjia/list?cid=8" },
        { id: 2, name: "工业服务", url: "/home?cid=2" },
        { id: 3, name: "工业商城", url: "/home?cid=3" },
        { id: 4, name: "其他服务", url: "/home?cid=4" },
      ],
    };
  },
};
</script>