<template>
  <div class="supplier">
    <supplier-menu :active="currentIndex" />
 
    <div class="data-list">
      <div class="content">
        <div class="data-list-main flex_b">
          <div class="data-list-l">
            <div class="not-data" v-if="!datalist.length && !loading">
              无数据
            </div>
            <div class="loading" v-if="loading">
              <i class="el-icon-loading"></i>数据加载中...
            </div>
            <div class="list-main">
              <div
                class="item flex_b"
                @click="goDetail(item)"
                v-for="item in datalist"
                :key="item.id"
              >
                <el-image :src="item.shortLogo" lazy></el-image>
                <div class="info flex_b">
                  <div class="info-l">
                    <div class="title">
                      <h3>
                        {{ item.title }}
                      </h3>
                    
                      
                    </div>
                    <div class="else">
                      
                    </div>
                    
                    <div class="" v-html="item.description"> </div>    
                  </div>
           
                </div>
              </div>
              <div class="page">
                <el-pagination
                  background
                  :page-size="pageSize"
                  :current-page.sync="pageNo"
                  @current-change="currentChange"
                  :layout="`prev, pager, next${total > 120 ? ',jumper' : ''}`"
                  prev-text="上一页"
                  next-text="下一页"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
        
          </div>
          <div class="data-list-r">
            <fast-up />
            <ad />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import supplierMenu from "@/components/servers/xuqiudating-menu";


import ad from "@/components/servers/ad";
import fastUp from "@/components/servers/fastUp";
export default {
  components: {
    supplierMenu,
    
    ad,
    fastUp,
  },
  data() {
    return {
      currentIndex:10,
       isWap:false,
      upVisible: false,
      pageNo: 1,
      pageSize: 12,
      total: 0,
      datalist: [],
      companyJson: {},
      loading: false,
      gradeActives: "-1",
      hasPays: false,
      params: {
        cmsHelpCategoryId: '004'
      },
      keyword:""
    };
  },
  watch: {
    "$store.state.keyword"(newval, oldval) {
      this.pageNo = 1;
      this.keyword = newval;
      this.getData();
    },
  },
  methods: {
    filterChange({ categoryCode, areaCode, yzCompanyAuthItems }) {
      this.params = {
        categoryCode: categoryCode || "",
        areaCode: areaCode || "",
        yzCompanyAuthItems: yzCompanyAuthItems || "",
        orderBy: this.params.orderBy,
      };
      this.hasPays = yzCompanyAuthItems == "4";
      // document.documentElement.scrollTop =
      //   document.querySelector(".sort-main").offsetTop - 150;
      this.getData();
    },
    sortChange({ hasPay, areaCode, orderBy }) {
      if (hasPay) {
        this.gradeActives = "4";
      } else if (this.gradeActives == "4") {
        this.gradeActives = "";
      }
      this.params = {
        areaCode: areaCode || "",
        yzCompanyAuthItems: this.gradeActives,
        orderBy: orderBy || "",
      };
      this.getData();
    },
    currentChange(val) {
      this.pageNo = val;
      this.getData();
    },
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/xuqiuduijie/detail?id=${item.id}`;
    },
    goServers(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/f/shopservie-view-${item.cropCode}-${item.serviceId}.html`;
    },
    goChat(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        "https://www.huixianggongye.com/f/contactKefu?company_id=" + item.companyId;
    },
    getData() {
      this.loading = true;
      this.datalist = [];
      this.$api
        .get("/api/v1/getYzCmsHelpList", {
          params: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            ...this.params,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.list) {
            this.datalist = res.data.list;
          }
          this.total = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.isWap = $(window).width()<1000;
    let cmsHelpCategoryId = this.$route.query.cmsHelpCategoryId;
if (cmsHelpCategoryId ) {
  this.params.cmsHelpCategoryId = cmsHelpCategoryId;
} else{
  this.params.cmsHelpCategoryId="1856455518364344324";
}

if(this.$route.query.cid){
  this.currentIndex=this.$route.query.cid;
}else{
  this.currentIndex=10;
}

    this.getData();
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/zixun.scss";
</style>